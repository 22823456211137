import {
  InitialFormValues,
  InitialState,
} from 'features/taskManager/index.interface';
import { TASKS } from 'features/taskManager/mocks';

import { toI18n } from 'util/i18n';

export const I18N_PATH = 'task_manager';
export const I18N_PATH_V2 = `${I18N_PATH}.v2`;
export const SINGLE_TASK_FORM_I18N_PATH = `${I18N_PATH_V2}.single_task_form`;
export const NEW_TASK_BUTTON_I18N_PATH = `${I18N_PATH_V2}.new_task_button`;
export const NO_ACTIVE_TASKS_I18N_PATH = `${I18N_PATH_V2}.no_active_tasks`;
export const TABS_I18N_PATH = `${I18N_PATH_V2}.tabs`;
export const TASK_I18N_PATH = `${I18N_PATH_V2}.task`;
export const TOOLBAR_I18N_PATH = `${I18N_PATH}.toolbar`;
export const HERO_I18N_PATH = `${I18N_PATH}.hero`;
export const DETAILS_I18N_PATH = `${I18N_PATH}.details`;
export const TASK_MANAGER_SLICE_NAME = 'TASK_MANAGER';
export const VIEW_TYPE = { BETA: 'beta' };
export const SUCCESS_ALERT_DELAY = 3000;

export const INITIAL_STATE: InitialState = {
  tasks: TASKS,
  hasPurchasedTaskManager: false,
};

export const TABS = [
  {
    value: 0,
    label: toI18n(`${TABS_I18N_PATH}.manage`),
    badgeText: '',
  },
];

export const REPEATING_OPTIONS = [
  {
    label: toI18n(`${SINGLE_TASK_FORM_I18N_PATH}.daily`),
    value: 'daily',
  },
  {
    label: toI18n(`${SINGLE_TASK_FORM_I18N_PATH}.weekly`),
    value: 'weekly',
  },
  {
    label: toI18n(`${SINGLE_TASK_FORM_I18N_PATH}.does_not_repeat`),
    value: 'does_not_repeat',
  },
];

export const ADD_SINGLE_TASK_FORM_INITIAL_VALUES: InitialFormValues = {
  title: '',
  dueTime: '',
  dueDate: '',
  startTime: '',
  startDate: '',
  repeatOn: [],
  description: '',
  deptRoleEmp: [],
  assignTo: '',
  repeating: '',
};

const TIMES = [
  '12:00 am',
  '12:15 am',
  '12:30 am',
  '12:45 am',
  '1:00 am',
  '1:15 am',
  '1:30 am',
  '1:45 am',
  '2:00 am',
  '2:15 am',
  '2:30 am',
  '2:45 am',
  '3:00 am',
  '3:15 am',
  '3:30 am',
  '3:45 am',
  '4:00 am',
  '4:15 am',
  '4:30 am',
  '4:45 am',
  '5:00 am',
  '5:15 am',
  '5:30 am',
  '5:45 am',
  '6:00 am',
  '6:15 am',
  '6:30 am',
  '6:45 am',
  '7:00 am',
  '7:15 am',
  '7:30 am',
  '7:45 am',
  '8:00 am',
  '8:15 am',
  '8:30 am',
  '8:45 am',
  '9:00 am',
  '9:15 am',
  '9:30 am',
  '9:45 am',
  '10:00 am',
  '10:15 am',
  '10:30 am',
  '10:45 am',
  '11:00 am',
  '11:15 am',
  '11:30 am',
  '11:45 am',
  '12:00 pm',
  '12:15 pm',
  '12:30 pm',
  '12:45 pm',
  '1:00 pm',
  '1:15 pm',
  '1:30 pm',
  '1:45 pm',
  '2:00 pm',
  '2:15 pm',
  '2:30 pm',
  '2:45 pm',
  '3:00 pm',
  '3:15 pm',
  '3:30 pm',
  '3:45 pm',
  '4:00 pm',
  '4:15 pm',
  '4:30 pm',
  '4:45 pm',
  '5:00 pm',
  '5:15 pm',
  '5:30 pm',
  '5:45 pm',
  '6:00 pm',
  '6:15 pm',
  '6:30 pm',
  '6:45 pm',
  '7:00 pm',
  '7:15 pm',
  '7:30 pm',
  '7:45 pm',
  '8:00 pm',
  '8:15 pm',
  '8:30 pm',
  '8:45 pm',
  '9:00 pm',
  '9:15 pm',
  '9:30 pm',
  '9:45 pm',
  '10:00 pm',
  '10:15 pm',
  '10:30 pm',
  '10:45 pm',
  '11:00 pm',
  '11:15 pm',
  '11:30 pm',
  '11:45 pm',
];

export const TIMESTAMPS: { label: string; value: string }[] = TIMES.map(
  time => ({ label: time, value: time })
);

export const ASSIGN_TO_OPTIONS = [
  {
    label: toI18n(`${SINGLE_TASK_FORM_I18N_PATH}.department`),
    value: 'department',
  },
  {
    label: toI18n(`${SINGLE_TASK_FORM_I18N_PATH}.role`),
    value: 'role',
  },
  {
    label: toI18n(`${SINGLE_TASK_FORM_I18N_PATH}.employee`),
    value: 'employee',
  },
];
