import { SelectOptionsType } from 'fe-design-base/atoms/Select';

import { toI18n } from 'util/i18n';

import { CandidateInfo } from './types';

const DEFAULT_ROUTE = '/hiring/background_checks';

export const ROUTES = {
  STATUS: `${DEFAULT_ROUTE}/company/application`,
  APPLY: `${DEFAULT_ROUTE}/company/application`,
  CHECKS: `${DEFAULT_ROUTE}/company/checks`,
  CHECKS_PAGINATED: (page: number) =>
    `${DEFAULT_ROUTE}/company/checks?page=${page}`,
};

export const SLICE_NAME = 'backgroundCheckSlice';

export const standardPriceList = [
  {
    primary: toI18n(
      'hr_pro.background_checks.standard.social_security_tracing'
    ),
  },
  {
    primary: toI18n('hr_pro.background_checks.standard.sex_offender_database'),
  },
  {
    primary: toI18n('hr_pro.background_checks.standard.county'),
  },
  {
    primary: toI18n('hr_pro.background_checks.standard.excluded_parties'),
  },
];

export const proPriceList = [
  {
    primary: toI18n('hr_pro.background_checks.pro.seven_year_unlimited_county'),
  },
  {
    primary: toI18n('hr_pro.background_checks.pro.national_criminal_database'),
  },
  {
    primary: toI18n('hr_pro.background_checks.pro.federal_district'),
  },
  {
    primary: toI18n('hr_pro.background_checks.pro.employment'),
  },
];

export const companyTypes: SelectOptionsType = [
  {
    label: toI18n(
      'hr_pro.onboarding.company.company_types.sole_proprietorship'
    ),
    value: 1,
  },
  {
    label: toI18n('hr_pro.onboarding.company.company_types.partnership'),
    value: 2,
  },
  {
    label: toI18n('hr_pro.onboarding.company.company_types.c_corp'),
    value: 3,
  },
  {
    label: toI18n('hr_pro.onboarding.company.company_types.s_corp'),
    value: 4,
  },
  {
    label: toI18n('hr_pro.onboarding.company.company_types.llc'),
    value: 5,
  },
];
export const industryTypes: SelectOptionsType = [
  {
    label: toI18n(
      'hr_pro.onboarding.company.industries.agriculture_forestry_fishing_hunting'
    ),
    value: 'agriculture_forestry_fishing_hunting',
  },
  {
    label: toI18n('hr_pro.onboarding.company.industries.mining'),
    value: 'mining',
  },
  {
    label: toI18n('hr_pro.onboarding.company.industries.utilities'),
    value: 'utilities',
  },
  {
    label: toI18n('hr_pro.onboarding.company.industries.construction'),
    value: 'construction',
  },
  {
    label: toI18n('hr_pro.onboarding.company.industries.manufacturing'),
    value: 'manufacturing',
  },
  {
    label: toI18n('hr_pro.onboarding.company.industries.wholesale_trade'),
    value: 'wholesale_trade',
  },
  {
    label: toI18n('hr_pro.onboarding.company.industries.retail_trade'),
    value: 'retail_trade',
  },
  {
    label: toI18n(
      'hr_pro.onboarding.company.industries.transportation_warehousing'
    ),
    value: 'transportation_warehousing',
  },
  {
    label: toI18n('hr_pro.onboarding.company.industries.information'),
    value: 'information',
  },
  {
    label: toI18n('hr_pro.onboarding.company.industries.finance_insurance'),
    value: 'finance_insurance',
  },
  {
    label: toI18n(
      'hr_pro.onboarding.company.industries.real_estate_rental_lease'
    ),
    value: 'real_estate_rental_lease',
  },
  {
    label: toI18n(
      'hr_pro.onboarding.company.industries.professional_scientific_technical_services'
    ),
    value: 'professional_scientific_technical_services',
  },
  {
    label: toI18n(
      'hr_pro.onboarding.company.industries.management_of_company_and_enterprises'
    ),
    value: 'management_of_company_and_enterprises',
  },
  {
    label: toI18n(
      'hr_pro.onboarding.company.industries.administrative_support_waste_management_remediation_services'
    ),
    value: 'administrative_support_waste_management_remediation_services',
  },
  {
    label: toI18n('hr_pro.onboarding.company.industries.educational_services'),
    value: 'educational_services',
  },
  {
    label: toI18n(
      'hr_pro.onboarding.company.industries.healthcare_social_assistance'
    ),
    value: 'healthcare_social_assistance',
  },
  {
    label: toI18n(
      'hr_pro.onboarding.company.industries.arts_entertainment_recreation'
    ),
    value: 'arts_entertainment_recreation',
  },
  {
    label: toI18n(
      'hr_pro.onboarding.company.industries.accommodation_and_food_services'
    ),
    value: 'accommodation_and_food_services',
  },
  {
    label: toI18n('hr_pro.onboarding.company.industries.other_services'),
    value: 'other_services',
  },
  {
    label: toI18n('hr_pro.onboarding.company.industries.public_administration'),
    value: 'public_administration',
  },
];

export const candidateCheckMockList: CandidateInfo[] = [
  {
    name: 'John Doe',
    package_details: 'Pro',
    status: 'Done',
    score: 'Pass',
    purchase_date: '2023-02-20',
    report: 'View Report',
  },
  {
    name: 'Jane Smith',
    status: 'In progress',
    package_details: 'Pro',
    score: 'Needs attention',
    purchase_date: '2023-02-20',
    report: 'Review',
  },
];

export const ownerTitleSelectOptions: SelectOptionsType = [
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.ceo'),
    value: 1,
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.cfo'),
    value: 2,
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.coo'),
    value: 3,
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.cpa'),
    value: 4,
  },
  {
    label: toI18n(
      'hr_pro.onboarding.owner.form.title_options.chairman_of_the_board'
    ),
    value: 5,
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.conservator'),
    value: 6,
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.controller'),
    value: 7,
  },
  {
    label: toI18n(
      'hr_pro.onboarding.owner.form.title_options.corporate_officer'
    ),
    value: 8,
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.director'),
    value: 9,
  },
  {
    label: toI18n(
      'hr_pro.onboarding.owner.form.title_options.director_of_finance'
    ),
    value: 10,
  },
  {
    label: toI18n(
      'hr_pro.onboarding.owner.form.title_options.director_of_operations'
    ),
    value: 11,
  },
  {
    label: toI18n(
      'hr_pro.onboarding.owner.form.title_options.executive_director'
    ),
    value: 12,
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.finance_manager'),
    value: 13,
  },
  {
    label: toI18n(
      'hr_pro.onboarding.owner.form.title_options.managing_director'
    ),
    value: 14,
  },
  {
    label: toI18n(
      'hr_pro.onboarding.owner.form.title_options.managing_member_partner_member'
    ),
    value: 15,
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.non_for_profit'),
    value: 16,
  },
  {
    label: toI18n(
      'hr_pro.onboarding.owner.form.title_options.officer_operations_manager'
    ),
    value: 17,
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.partner'),
    value: 18,
  },
  {
    label: toI18n(
      'hr_pro.onboarding.owner.form.title_options.power_of_attorney'
    ),
    value: 19,
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.president'),
    value: 20,
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.principal'),
    value: 21,
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.trustee'),
    value: 22,
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.vice_president'),
    value: 23,
  },
  {
    label: toI18n(
      'hr_pro.onboarding.owner.form.title_options.area_vice_president'
    ),
    value: 24,
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.shareholder'),
    value: 25,
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.doctor'),
    value: 26,
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.attorney'),
    value: 27,
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.treasurer'),
    value: 28,
  },
];
