import { Tasks } from './index.interface';

export const DEPARTMENTS = [
  {
    label: 'Management',
    value: 'management',
  },
  {
    label: 'Janitorial',
    value: 'janitorial',
  },
];

export const EMPLOYEES = [
  {
    label: 'Susan Aaron',
    value: '20',
  },
  {
    label: "Ze'ev Heather",
    value: '30',
  },
];

const ROLES = [
  {
    label: 'Cashier',
    value: 'cashier',
  },
  {
    label: 'Manager',
    value: 'manager',
  },
];

export const DEPT_ROLE_EMP: {
  [label: string]: { label: string; value: string }[];
} = {
  department: DEPARTMENTS,
  role: ROLES,
  employee: EMPLOYEES,
};

export const TASKS: Tasks = [
  {
    id: 728,
    type: 'task_item',
    name: 'Wipe the chairs',
    description: 'Wipe em all today!',
    due_date: '2024-09-20T04:09:49+00:00',
    assignee_type: 'User',
    assignee_id: 3900,
    occurrence_pattern: 'does_not_repeat',
    assignee_name: 'Steve',
  },
  {
    id: 14,
    type: 'task_item',
    name: 'Wipe the counters',
    due_date: '2024-09-20T04:09:49+00:00',
    assignee_type: 'User',
    assignee_id: 3900,
    occurrence_pattern: 'daily',
    assignee_name: 'Steve',
  },
  {
    id: 3431,
    name: 'Evening Checklist',
    description: 'Have a great morning!',
    type: 'task_list',
    occurrence_pattern: 'does_not_repeat',
    due_date: '2024-09-20T04:09:49+00:00',
    occurrence_day: null,
    task: [
      {
        id: 1,
        name: 'Check seafood stock',
        description: 'Make sure we have enough lobster',
        assignee_type: 'User',
        assignee_id: 3900,
        assignee_name: 'Bob',
      },
      {
        id: 12,
        name: 'Check spice stock',
        description: 'Make sure we have enough curry',
        assignee_type: 'User',
        assignee_id: 3900,
        assignee_name: 'Bob',
      },
      {
        id: 13,
        name: 'Check cheese stock',
        description: 'Make sure we have enough mozzarella',
        assignee_type: 'User',
        assignee_id: 3900,
        assignee_name: 'Bob',
      },
      {
        id: 41,
        name: 'Check meat stock',
        description: 'Make sure we have enough beef',
        assignee_type: 'User',
        assignee_id: 3900,
        assignee_name: 'Bob',
      },
      {
        id: 431,
        name: 'Check seafood stock',
        description: 'Make sure we have enough lobster',
        assignee_type: 'User',
        assignee_id: 3900,
        assignee_name: 'Bob',
      },
      {
        id: 321,
        name: 'Check seafood stock',
        description: 'Make sure we have enough lobster',
        assignee_type: 'User',
        assignee_id: 3900,
        assignee_name: 'Bob',
      },
      {
        id: 1423,
        name: 'Check seafood stock',
        description: 'Make sure we have enough lobster',
        assignee_type: 'User',
        assignee_id: 3900,
        assignee_name: 'Bob',
      },
    ],
  },
  {
    id: 64254,
    name: 'Afternoon Checklist',
    description: 'Have a great afternoon!',
    type: 'task_list',
    occurrence_pattern: 'weekly',
    occurrence_day: null,
    due_date: '2024-09-20T04:09:49+00:00',
    task: [],
  },
  {
    id: 638254,
    name: 'Morning Checklist',
    type: 'task_list',
    occurrence_pattern: 'weekly',
    occurrence_day: null,
    due_date: '2024-09-20T04:09:49+00:00',
    task: [
      {
        id: 1,
        name: 'Check seafood stock',
        description: 'Make sure we have enough lobster',
        assignee_type: 'User',
        assignee_id: 3900,
        assignee_name: 'Bob',
      },
    ],
  },
];
