import React, { useCallback } from 'react';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import Button from 'fe-design-base/molecules/Button';
import List from 'fe-design-base/molecules/List/List';

import { moment } from 'util/dateTime';
import { toI18n } from 'util/i18n';
import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUx, useTrackUxOnMount } from 'util/uxEvents';

import success_src from './confirmation_success.png';
import { successData } from './util';

interface SuccessProps {
  payPeriodStartDate: string;
  closeDrawer: () => void;
}

const Success: React.FC<SuccessProps> = ({
  payPeriodStartDate,
  closeDrawer,
}) => {
  const formattedPayPeriodStartDate =
    moment(payPeriodStartDate).format('dddd, MMMM Do');
  const trackUx = useTrackUx();

  useTrackUxOnMount(EVENT_ACTIONS.SIDE_DRAWER_VIEWED, TRACK_ACTION_TYPES.VIEW, {
    stage: 'checkout_completed',
  });

  const handleOnClickPrimary = useCallback(() => {
    trackUx(EVENT_ACTIONS.BUTTON_CLICKED, TRACK_ACTION_TYPES.CLICK, {
      button_text: toI18n('checkout_drawer.success.cta.primary'),
      element: 'primary_cta',
      stage: 'checkout_completed',
    });
    closeDrawer?.();
  }, [closeDrawer, trackUx]);

  const handleOnClickSecondary = useCallback(() => {
    trackUx(EVENT_ACTIONS.BUTTON_CLICKED, TRACK_ACTION_TYPES.CLICK, {
      button_text: toI18n('checkout_drawer.success.cta.secondary'),
      element: 'secondary_cta',
      stage: 'checkout_completed',
    });
    closeDrawer?.();
  }, [closeDrawer, trackUx]);

  return (
    <Box mt={122}>
      <Box hcenter>
        <img src={success_src} style={{ width: 164, height: 164 }} />
      </Box>
      <Box gap={24} column mt={40}>
        <Text variant="heading2" i18n="checkout_drawer.success.title" />
        <Text
          variant="body"
          i18n="checkout_drawer.success.subtitle"
          i18nProps={{ payPeriodStartDate: formattedPayPeriodStartDate }}
          asHTML
        />
        <List data={successData} />
      </Box>
      <Box gap={16} mt={56} column>
        <Button
          variant="primary"
          linkTo="/settings/tip_manager"
          onClick={handleOnClickPrimary}
        >
          {toI18n('checkout_drawer.success.cta.primary')}
        </Button>
        <Button
          variant="secondary"
          linkTo="/timesheets/pay_period_review"
          onClick={handleOnClickSecondary}
        >
          {toI18n('checkout_drawer.success.cta.secondary')}
        </Button>
      </Box>
    </Box>
  );
};

export default Success;
